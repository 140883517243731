.lb_top_curve {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.lb_top_curve svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 192px;
}

.lb_top_curve .shape-fill {
  fill: #064757;
}

.lb_bottom_curve {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.lb_bottom_curve svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 456px;
}

.lb_bottom_curve .shape-fill {
  fill: #064757;
}
.custom-shape-divider-top-1634174584 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1634174584 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 269px;
}

.custom-shape-divider-top-1634174584 .shape-fill {
  fill: #064757;
}

.lb_top_curve {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.lb_top_curve svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 150px;
  transform: rotateY(180deg);
}

.lb_top_curve .shape-fill {
  fill: #064757;
}
