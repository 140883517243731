.details_showcase_wrap {
  background-image: url("../../lib/images/singlpdt.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 80px 20px;
}
@media only screen and (max-width: 600px) {
  .details_showcase_wrap {
    background-image: none;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background: #094c59;
    padding: 5px 0px 50px 0px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}
@media only screen and (max-width: 600px) {
  .report_wrap {
    margin-top: 80px;
  }
  .report_wrap .right {
    margin-top: 50px;
  }
}
.details_showcase_wrap h6 {
  font-size: 23px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
}

.detail_bread {
  margin: 0px;
  padding: 0px;
  font-size: 15px !important;
}

@media only screen and (max-width: 600px) {
  .detail_bread {
    margin: 0px;
    padding: 0px;
    font-size: 10px !important;
  }
}

.detail_wrap {
  margin-top: -50px;
  border-radius: 10px;
  box-shadow: 0 25px 98px 0 rgb(0 0 0 / 3%);
}
.summaryTwo {
  margin: 2px;
}

.detail_wrap small {
  color: #094c59;
  font-weight: bold;
}

.img_popping_wrap {
  position: fixed;

  padding-top: 10vh;
  z-index: 9999;

  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);

  /* padding-top: "3vh"; */
}
