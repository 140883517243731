.bottom_footer {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: #094c59;

  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;

  padding-top: 10px;
  height: 35px !important;
}
.bottom_footer:hover {
  cursor: pointer;
}

@media screen and (max-width: 720px) {
  .bottom_footer {
    width: 100%;
    border-radius: none;
    padding-top: 10px;
  }
}
