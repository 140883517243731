.job_application_form_wrap {
  padding: 20px 10%;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: #fff;
  margin-top: 30px;
}

.job_application_form_wrap h2 {
  font-weight: bold;

  text-align: center;
  margin-bottom: 30px;
  font-weight: 500;
}

@media only screen and (max-width: 600px) {
  .job_application_form_wrap {
    padding: 0%;
    border-radius: 10px;
    margin-bottom: 20px;
    background-color: none !important;
    margin-top: 20px;
  }

  .job_application_form_wrap select:nth-child(1) {
    margin-bottom: 30px;
  }

  .check_span {
    font-size: 10px !important;
    line-height: 15px !important;
  }
}

.application_page {
  background-image: url("https://levbitz.com/static/media/tilt.099fe2495cb620fcc3f6.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #f5f5f5;
}
