.career_wrap {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/myassetapi.appspot.com/o/Ug365%2Fbanner.jpeg?alt=media&token=7d6fe766-a44e-4717-99a9-a09f5321acc9");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px rgb(5 6 66 / 50%);
  min-height: 30vh;
  padding-top: 10vh;
}

.fouth_column {
  margin-top: 30px;
}

/* media query start */

@media only screen and (max-width: 600px) {
  .career_wrap h2 {
    font-size: 20px;
    font-weight: 300;
  }

  .third_column {
    margin-top: -30px;
  }
  .fouth_column {
    margin-top: 0px;
  }
}

/*media query stop*/
