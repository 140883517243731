.walk_through_wrap h4 {
  font-size: 28px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}

.walk_through_wrap h6 {
  font-size: 18px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}

.lower_design {
  background-color: coral;
  font-size: 32px;
  height: 5px;
  width: 150px;
  margin-bottom: 20px;
  margin-top: 10px;
}
