/*
=============== 
Variables
===============
*/
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);

  --color-bg: #040c18;
  --color-footer: #031b34;
  --color-blog: #042c54;
  --color-text: #81afdd;
  --color-subtext: #ff8a71;
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;
  --clr-red-dark: hsl(360, 67%, 44%);
  --clr-red-light: hsl(360, 71%, 66%);
  --clr-green-dark: hsl(125, 67%, 44%);
  --clr-green-light: hsl(125, 71%, 66%);
  --clr-black: #222;
  --transition: all 0.3s linear;
  --spacing: 0.1rem;
  --radius: 0.25rem;
  --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  --dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  --max-width: 1170px;
  --fixed-width: 620px;
}
/*
=============== 
Global Styles
===============
*/

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden !important;
}
body {
  font-family: "Nunito", sans-serif;
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
  overflow-y: hidden !important; /* Hide vertical scrollbar */
  overflow-x: hidden !important; /* Hide horizontal scrollbar */
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  letter-spacing: var(--spacing);
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}
h1 {
  font-size: 3rem;
}
h2 {
  font-size: 3.5rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  font-size: 2rem;
}
h3 {
  font-size: 1.25rem;
}
h4 {
  font-size: 0.875rem;
}
p {
  margin-bottom: 1.25rem;
  color: var(--clr-grey-5);
}

@media only screen and (max-width: 600px) {
  p {
    font-size: 14.5px;
  }
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }
  h2 {
    font-size: 2.5rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1rem;
  }
  body {
    font-size: 1rem;
  }
  h1,
  h2,
  h3,
  h4 {
    line-height: 1;
  }
}
/*  global classes */

/* @media screen and (max-width: 720px) {
  .container {
    width: 100% !important;
    padding: 5px !important;
  }
} */

/* section */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

/*
=============== 
Stripe Styles
===============
*/
.nav {
  /* height: 5rem; */
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: transparent; */
  /* background: #094c59; */
  background: #094c59;
  /* background: #2e3192; */
  position: relative;
  z-index: 1;
}
.nav-center {
  width: 98vw !important;
  max-width: var(--max-width);
}
.nav-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 2rem;
}
/* .btn {
  font-size: 1rem;
  padding: 0.25rem 0.75rem;
  border-radius: var(--radius);
  border-color: transparent;
  color: white;
  background: var(--clr-black);
  cursor: pointer;
  transition: var(--transition);
} */
/* .btn:hover {
  background: var(--clr-grey-5);
} */
.nav-links {
  display: none;
}
.signin-btn {
  display: none;
}
.hero::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 65%;
  top: 0;
  left: 0;
  /* background: url(./lib/images/show.png) no-repeat center center; */
  background: url(./lib/images/hero.svg);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}
.hero {
  position: relative;
  min-height: 100vh;
  margin-top: -5rem;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}
.hero-center {
  width: 90vw;
  max-width: var(--max-width);
  display: grid;
  align-items: center;
}

.hero-info h1 {
  text-transform: none;
  max-width: 500px;
  margin-bottom: 2rem;
}
.hero-info p {
  max-width: 35em;
  line-height: 1.8;
}
.hero-images {
  display: none;
}

/* nav media query */
@media screen and (min-width: 800px) {
  .nav-center {
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
  }
  .toggle-btn {
    display: none;
  }
  .signin-btn {
    display: inline-block;
    margin-right: 10px;
  }
  .nav-links {
    display: block;
    justify-self: right;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    text-align: center;
    height: 100%;
    display: grid;
    align-items: center;
  }
  .nav-links li {
    height: 100%;
  }
  .link-btn {
    height: 100%;
    background: transparent;
    border-color: transparent;
    /* font-size: 1.1rem; */
    color: white;
    text-transform: capitalize;
    /* letter-spacing: 1px;
    width: 10rem; */
  }
}

/* hero media query */
@media screen and (min-width: 800px) {
  .hero::before {
    background-size: contain;
    height: 100%;
  }
  .hero-center {
    grid-template-columns: 2fr 1fr;
  }

  .hero-info h1 {
    font-size: 3rem;
  }
  .hero-info p {
    font-size: 1.25rem;
  }
  .hero-images {
    display: block;
    justify-self: center;
  }
  .phone-img {
    width: 12rem;
  }
}

@media screen and (min-width: 1200px) {
  .hero-center {
    grid-template-columns: 2fr 1fr;
    align-items: end;
    padding-bottom: 12vh;
  }
  .hero-info h1 {
    max-width: 100%;
    font-size: 5.5rem;
  }
  .hero-images {
    align-self: end;
  }
  .phone-img {
    width: 15rem;
  }
}
@media screen and (min-width: 1400px) {
  .hero-center {
    padding-bottom: 20vh;
  }
  .phone-img {
    width: 17rem;
  }
}

/* links */
.submenu {
  background: var(--clr-white);
  box-shadow: var(--dark-shadow);
  position: absolute;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  display: none;
  padding: 2rem;
  border-radius: var(--radius);
  transition: var(--transition);
}
.submenu::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid var(--clr-white);
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.submenu.show {
  display: block;
}

.submenu-center {
  display: grid;
  gap: 0.25rem 2rem;
}
.col-2 {
  grid-template-columns: repeat(2, 1fr);
}
.col-3 {
  grid-template-columns: repeat(3, 1fr);
}
.col-4 {
  grid-template-columns: repeat(4, 1fr);
}
.submenu h4 {
  margin-bottom: 1.5rem;
}
.submenu-center a {
  width: 10rem;
  display: block;
  color: var(--clr-grey-1);
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.submenu-center svg {
  color: var(--clr-grey-5);
  margin-right: 1rem;
}

.levbitz_container {
  width: 92%;
  margin: 0px auto;
}

@media only screen and (max-width: 620px) {
  .levbitz_container {
    width: 100%;
    margin: 0px auto;
  }
}

.levbitz_container_two {
  width: 90%;
  margin: 0px auto;
}

.chip {
  display: inline-block;
  height: 32px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: #e4e4e4;
  margin-bottom: 0px !important;
  margin-right: 5px;
}

.breadcrumb:before {
  content: "\E5CC";
  color: rgba(255, 255, 255, 0.7);
  vertical-align: top;
  display: inline-block;
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 10px;
  margin: 0 10px 0 8px;
  -webkit-font-smoothing: antialiased;
}

.levbitz_top_main_filter {
  height: 30px !important;
  width: 200px !important;
  text-transform: lowercase !important;
  background: #094c59 !important;
  margin-top: 5px !important;
  color: #fff !important;
}
