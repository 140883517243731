/* sidebar */
.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  visibility: hidden;
  z-index: -1;
  transition: var(--transition);
  transform: scale(0);
  background: rgba(0, 0, 0, 0.5);
}
.sidebar-wrapper.show {
  visibility: visible;
  z-index: 2;
  transform: scale(1);
}
.sidebar {
  width: 92vw;
  height: 95vh;
  max-width: var(--fixed-width);
  background: var(--clr-white);
  border-radius: var(--radius);
  box-shadow: var(--dark-shadow);
  position: relative;
  padding: 4rem 2rem;
  overflow-y: scroll;
}
.close-btn {
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: var(--clr-grey-5);
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}
.sidebar article {
  margin-bottom: 2rem;
}
.sidebar-sublinks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 0.25rem;
}
.sidebar-sublinks a {
  display: block;
  color: var(--clr-grey-1);
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
.sidebar-sublinks svg {
  color: var(--clr-grey-5);
  margin-right: 1rem;
}

@media screen and (min-width: 800px) {
  .sidebar-wrapper {
    display: none;
  }
}

.mobo-sidebar-link {
  font-size: 14px !important;
  margin: 8px;
  font-weight: 700;
}
.mobo-sidebar-title {
  font-size: 14px !important;
  font-weight: bold;
  background: #094c59;
  padding-left: 5px;
  border-radius: 2px;
  color: #fff;
  margin-bottom: 10px;
}

.sidebar-mob-btn {
  background: #094c59;
  padding: 5px 10px;
  border-radius: 5px;
}

.sidebar-mob-btn :hover {
  background: #094c59;
  color: #fff;
  cursor: pointer;
  transition: 0.5 ease-in-out;
}
