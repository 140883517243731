.single_job_showcase_wrap {
  background-image: url("https://demo.w3layouts.com/demosWTR/Freedom/25-04-2020/hr-crew-freedom-demo_Free/422566165/web/assets/images/b0.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 1000px rgb(5 6 66 / 50%);
  min-height: 30vh;
  padding-top: 10vh;
  margin-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  .single_job_showcase_wrap {
    background-image: url("https://demo.w3layouts.com/demosWTR/Freedom/25-04-2020/hr-crew-freedom-demo_Free/422566165/web/assets/images/b0.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: inset 0 0 0 1000px rgb(5 6 66 / 50%);
    min-height: 15vh;
    padding-top: 2vh;
    margin-bottom: 10px;
  }

  .single_job_title {
    font-size: 1.5rem;
    font-weight: 300;
  }
}

.top_apply_btn {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .top_apply_btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
}

.single_job_tag {
  background: #f2f2f2;
  display: inline-block;
  color: #555a64;
  padding: 5px 10px;
  border-radius: 4px;
  border: 1px solid #cccaca;
  margin-bottom: 5px;
  font-size: 15px;
  margin-left: 5px;
}

.deadline_wrap {
  background: #00bcd4;
  padding: 5px 100px;
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: "center";
}

/* media query start */

.deadline_wrap {
  background: #00bcd4;
  padding: 5px 50px;
  margin-bottom: 20px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: "center";
  width: 100%;
}

/*media query stop*/
