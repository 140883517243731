.mega_showcase_banner {
  background-image: url("../../lib/images/detail.gif");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 30px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  object-fit: contain;
}
