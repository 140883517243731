.originalAccount {
  border: none;
  outline: none;
  color: #fff;
  background: coral;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  padding: 10px;
  cursor: grab;
}

.originalAccount:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.originalAccount:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.originalAccount:active {
  color: #000;
}

.originalAccount:active:after {
  background: transparent;
}

.originalAccount:hover:before {
  opacity: 1;
}

.originalAccount:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: #111; */
  background: coral;
  border-radius: 5px;
  left: 0;
  top: 0;
  /* border-radius: 10px; */
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

.originalAccountScrolled {
  background-color: coral;
}

@media screen and (max-width: 960px) {
  .originalAccountScrolled {
    background-color: red;
    width: 300px !important;
    margin-bottom: 20px !important;
    border-radius: 0px !important;
  }
}
@media screen and (max-width: 960px) {
  .originalAccount {
    background-color: transparent !important;
    /* border: 1px solid #fff !important; */
    margin-bottom: 20px !important;
    border-radius: 0px !important;
    width: 300px;
  }
}
