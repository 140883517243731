@media only screen and (max-width: 600px) {
  .nav-logo {
    padding-left: 10px !important;
  }
}

.nav-links li {
  height: 40px;
  margin-bottom: 10px;
}
