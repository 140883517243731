.alert-modal {
  /* background-color: #fff; */
  background-image: url("../../lib/images/modal.png");
  box-shadow: 20px 25px 98px 50px #0000007f;
  background-position: center;
  background-size: cover;
  margin-top: 10vh;
}

.alert-modal p {
  font-size: 14px !important;
}

@media screen and (min-width: 600px) {
  .alert-modal {
    /* width: 500px; */
    /* margin: 0 auto; */
  }
}

.myAlertWrap {
  position: fixed;

  padding-top: 5vh;
  z-index: 9999;

  width: 100%;
  height: 100%;
  background-color: rgba(31, 91, 66, 0.5);
  padding-top: "100vh";
}
