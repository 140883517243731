.post_wrap {
  overflow-x: hidden !important;
  width: 100% !important;
}

.input {
  padding: 10px 20px !important;
}

textarea {
  padding: 10px 20px !important;
}

.post_showcase_banner {
  background-color: #094c59;
  padding: 40px 20px;

  margin-bottom: 10px;
}

.post_showcase_banner h3 {
  font-weight: bold;
  font-size: 35px;
  margin: 0px;
  padding: 0px;
}
.post_showcase_banner h4 {
  font-weight: bold;
  font-size: 20px;
}

@media only screen and (max-width: 600px) {
  .post_showcase_banner {
    background-color: #094c59;
    padding: 30px 20px;

    margin-bottom: 10px;
  }

  .post_showcase_banner h3 {
    font-weight: bold;
    font-size: 20px;
    margin: 0px;
    padding: 0px;
  }
  .post_showcase_banner h4 {
    font-weight: 200;
    font-size: 15px;

    padding: 0px;
  }
}
