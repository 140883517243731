.category_mega_showcase_banner {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  background-image: linear-gradient(90deg, rgb(3 15 39/69%), rgb(3 15 39/70%)),
    url("../../lib/images/detail.gif");
  padding: 30px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  object-fit: contain;
}
